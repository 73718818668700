
class InsuranceHelper {

  static getCountryCodeFromPath() {
    const pathParts = window.location.pathname.split('/');
    const countryCode = pathParts[1];
    return countryCode.toUpperCase();
  }

  // Get insurance data based on the country code
  static getInsuranceInitData() {
    const storedData = localStorage.getItem("InsuranceInitData");

    if (storedData) {
      try {
        const allCountriesData = JSON.parse(storedData);
        const countryCode = InsuranceHelper.getCountryCodeFromPath();
        return allCountriesData[countryCode] || null; // If data for countryCode not found, return null
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
        return null; // Return null if there's an error in parsing
      }
    }

    // Return null if no data found
    return null;
  }

  static formatAsCurrency(value) {
    const userLocale = InsuranceHelper.getUserLocale();
    const formattedNumber = InsuranceHelper.formatNumber(value, userLocale);
    return formattedNumber;
  }

  // Helper function to get the user's locale
  static getUserLocale() {
    return navigator.language || 'en-US';
  }

  // Helper function to format numbers
  static formatNumber(value, locale = 'en-US') {
    return new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  static getTotal() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return InsuranceHelper.formatAsCurrency(data.totalAmount || 0);
    }      
    return "0,00";     
  }

  static getPremium() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return InsuranceHelper.formatAsCurrency(data.premiumAmount || 0);
    }      
    return "0,00";      
  }

  static getVAT() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return InsuranceHelper.formatAsCurrency(data.vatAmount || 0);
    }
    return "0,00";
  }

  static getVATExt() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return InsuranceHelper.formatAsCurrency(data.vatExtAmount || 0);
    }
    return "0,00";
  }

  static getVATExt2() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return InsuranceHelper.formatAsCurrency(data.vatExt2Amount || 0);
      //return data.vatExt2Amount || 0;
    }
    return "0,00";
  }

  static getIpidURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.ipidURL;
    } else {
      return "";
    }
  }

  static getTermsAndConditionsURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.termsAndConditionsURL;
    } else {
      return "";
    }      
  }

  static getPrivacyPolicyURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.privacyPolicyURL;
    } else {
      return "";
    }
  }

  static getClaimsFormURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.claimsFormURL;
    } else {
      return "";
    }
  }

  static getFicURL() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.ficURL;
    } else {
      return "";
    }
  }

  static getDocuments() {
    const data = InsuranceHelper.getInsuranceInitData()
    if(data) {
      return data.documentList;
    } else {
      return [];
    }
  }
}

export default InsuranceHelper;